import styled, { css } from 'styled-components';
import { atMinWidth } from '../atoms/breakpoints';
import { colors } from '../atoms/colors';
import { font } from '../atoms/typography';

export const sectionStyles = css`
  position: relative;
  width: 100%;
  max-width: 625px;
  display: flex;
  gap: 32px;
  justify-content: center;
  padding: 30px 23.5px 64px 23.5px;

  ${atMinWidth.md`
    max-width: 720px;
    padding: 147px 16px 64px;
  `}

  ${atMinWidth.lg`
    max-width: 100%;
    padding: 147px 55px 64px;
  `}

  ${atMinWidth.xl`
    max-width: 100%;
    padding: 179px 23.5px 96px;
  `}
`;

export const Social = styled.div`
  display: none;
  position: relative;
  top: -3px;
  z-index: 100;

  ${atMinWidth.xl`
    display: block;
  `}

  .social-share {
    position: sticky;
    top: 185px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  gap: 35px;

  ${atMinWidth.xl`
    display: grid;
    grid-template-columns: 50px 1fr 270px;
    justify-content: space-between;
  `}
`;

export const Post = styled.div`
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong {
    margin-bottom: 24px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .breadcrumb {
    margin-bottom: 0;
  }

  .video-title {
    ${font('display', 'xl', '700')}
    color: ${colors.gray[900]};
  }

  .blog-conversion-panel {
    margin-bottom: 0px;
  }

  .social-share {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    ${atMinWidth.xl`
      display: none;
    `}

    .social-share-list {
      flex-direction: row;
    }
  }
`;

export const Sidebar = styled.aside`
  display: none;
  width: 100%;

  ${atMinWidth.xl`
    display: block;
  `}
`;
